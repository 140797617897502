
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/breadcrumb/Overview.vue";
import LineSeparator from "@/view/pages/resources/documentation/base/breadcrumb/LineSeparator.vue";
import DotSeparator from "@/view/pages/resources/documentation/base/breadcrumb/DotSeparator.vue";

export default defineComponent({
  name: "breadcrumb",
  components: {
    Overview,
    LineSeparator,
    DotSeparator,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Breadcrumb");
    });
  },
});
